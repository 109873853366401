var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('aside',{staticClass:"filter-table-top-bar"},[_vm._t("before-nav"),(_vm.navItems.length > 0)?_c('nav',{staticClass:"d-flex align-center justify-space-between mb-4"},[_vm._l((_vm.navItems),function(navItem,navItemIndex){return [(navItemIndex > 0)?_c('div',{key:`divider_${navItem.code}`,class:[
          'd-flex flex-column',
          {
            'mx-1': _vm.successButtonOutsideNav,
            'mx-3': !_vm.successButtonOutsideNav,
          },
        ],style:({
          height: '16px',
        })},[_c('v-divider',{staticClass:"ma-0",attrs:{"vertical":""}})],1):_vm._e(),(navItem.imgUrl)?_c('v-btn',_vm._g({key:navItem.code,staticClass:"flex-shrink-0 flex-grow-0 px-0",attrs:{"loading":navItem.loading,"ripple":false,"text":"","small":"","height":"40","width":"40","color":"grey","min-width":"0"}},navItem.listeners),[_c('BaseImg',{staticClass:"flex-shrink-0",style:({ marginLeft: '-4px' }),attrs:{"src":navItem.imgUrl,"contain":"","width":"44","max-width":"none","aspect-ratio":"1"}})],1):_c('v-btn',_vm._g({key:navItemIndex,staticClass:"flex-shrink-0 flex-grow-0",attrs:{"loading":navItem.loading,"ripple":false,"icon":"","color":"grey"}},navItem.listeners),[_c('v-icon',[_vm._v(" "+_vm._s(navItem.icon)+" ")])],1)]}),(_vm.successButton && !_vm.successButtonOutsideNav)?_c('v-btn',_vm._g({staticClass:"text-uppercase text-center flex-grow-1 ml-4",attrs:{"disabled":_vm.successButton.disabled,"loading":_vm.successButton.loading,"to":_vm.successButton.to,"id":_vm.successButton.id,"exact":"","height":"40","color":"success"}},_vm.successButton.listeners),[_c('span',{class:[
          'flex-grow-1 font-weight-bold',
          {
            'pr-1 pl-5': _vm.successButton.icon,
          },
        ]},[_vm._v(" "+_vm._s(_vm.successButton.title)+" ")]),(_vm.successButton.icon)?_c('v-icon',{attrs:{"color":"rgba(255, 255, 255, 0.4)","size":"16"}},[_vm._v(" "+_vm._s(_vm.successButton.icon)+" ")]):_vm._e()],1):_vm._e()],2):_vm._e(),(!_vm.hiddenSearchInput)?_c('div',{staticClass:"filter-table-top-bar-input white"},[_c('v-icon',{staticClass:"filter-table-top-bar-input__icon my-auto",attrs:{"color":"grey","size":"16"}},[_vm._v(" $magnifyingGlass ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchStr),expression:"searchStr",modifiers:{"trim":true}}],ref:"searchInput",staticClass:"flex-grow-1 align-self-stretch filter-table-top-bar-input__input",attrs:{"placeholder":_vm.$vuetify.lang.t('$vuetify.dashboard.search'),"type":"text"},domProps:{"value":(_vm.searchStr)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchStr=$event.target.value.trim()},_vm.onInputSearchStr],"blur":function($event){return _vm.$forceUpdate()}}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowClearButton),expression:"isShowClearButton"}],staticClass:"filter-table-top-bar-input__clear-button my-auto",attrs:{"small":"","icon":"","color":"grey"},on:{"click":_vm.clearInputSearchStr}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" $close ")])],1)],1):_vm._e(),_vm._t("after-input"),(_vm.successButton && _vm.successButtonOutsideNav)?_c('v-btn',_vm._g({staticClass:"text-uppercase text-center mt-4",attrs:{"disabled":_vm.successButton.disabled,"loading":_vm.successButton.loading,"to":_vm.successButton.to,"block":"","height":"40","color":"success"}},_vm.successButton.listeners),[_c('span',{class:[
        'flex-grow-1 font-weight-bold',
        {
          'pr-1 pl-5': _vm.successButton.icon,
        },
      ]},[_vm._v(" "+_vm._s(_vm.successButton.title)+" ")]),(_vm.successButton.icon)?_c('v-icon',{attrs:{"color":"rgba(255, 255, 255, 0.4)","size":"16"}},[_vm._v(" "+_vm._s(_vm.successButton.icon)+" ")]):_vm._e()],1):_vm._e(),_c('BottomSheet',{attrs:{"dense":_vm.showedFilterFields},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('v-expand-transition',[(_vm.activeFilterField)?_c('div',[_c('v-btn',{staticClass:"d-flex ml-auto px-2",attrs:{"small":"","text":"","height":"24","color":"primary"},on:{"click":_vm.onBackBottomSheet}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.back"))+" ")]),_c('h3',{staticClass:"text-subtitle-1 px-3 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeFilterField.title)+": ")])],1):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [(_vm.showedTableHeaderItems)?_c('section',_vm._l((_vm.tableHeaderItems),function(tableHeader,tableHeaderIndex){return _c('aside',{key:`${tableHeader.value}_${tableHeaderIndex}`,class:[
            'd-flex align-center',
            {
              'mt-3': tableHeaderIndex > 0,
            },
          ]},[_c('span',{staticClass:"text-body-1 font-weight-medium black--text d-block flex-shrink-1 flex-grow-1 mr-7"},[_vm._v(" "+_vm._s(tableHeader.text)+" ")]),_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","hide-details":"","dense":"","input-value":_vm.isShowTableHeader(tableHeader.value)},on:{"change":function($event){return _vm.onChangeShowedTableHeaders(tableHeader.value, $event)}}})],1)}),0):(_vm.showedFilterFields)?_c('section',[_c('v-expand-transition',[(_vm.activeFilterField)?_c('div',[_c('nav',{key:"activeFilterField",staticClass:"pa-2"},_vm._l((_vm.activeFilterField.items),function(filterFieldItem,filterFieldItemIndex){return _c('v-checkbox',{key:`${filterFieldItem.value}_${filterFieldItemIndex}`,class:[
                  'pa-1',
                  {
                    'mt-0': filterFieldItemIndex === 0,
                    'mt-3': filterFieldItemIndex > 0,
                  },
                ],attrs:{"input-value":_vm.isSelectedFilterFieldItem({
                    filterFieldName: _vm.activeFilterField.name,
                    filterFieldItemValue: filterFieldItem.value,
                  }),"ripple":false,"hide-details":"","color":"primary"},on:{"change":function($event){return _vm.onSelectFilterFieldItem({
                    filterFieldName: _vm.activeFilterField.name,
                    filterFieldItemValue: filterFieldItem.value,
                    filterFieldItemSelected: $event,
                  })}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(filterFieldItem.imageSrc)?_c('BaseImg',{staticClass:"flex-shrink-0 flex-grow-0 mr-2",attrs:{"src":filterFieldItem.imageSrc,"width":"20"}}):_vm._e(),_vm._v(" "+_vm._s(filterFieldItem.text)+" ")]},proxy:true}],null,true)})}),1)]):_c('nav',{key:"filterFields"},_vm._l((_vm.filterFields),function(filterField,filterFieldIndex){return _c('v-btn',{key:`${filterField.value}_${filterFieldIndex}`,staticClass:"px-2 my-1",attrs:{"block":"","text":""},on:{"click":function($event){return _vm.setActiveFilterField(filterFieldIndex)}}},[_c('span',{staticClass:"flex-grow-1 text-left"},[_vm._v(" "+_vm._s(filterField.title)+" ")]),_c('v-icon',{attrs:{"size":"10","color":"blue-grey darken-4"}},[_vm._v(" $arrowR ")])],1)}),1)])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.showedBottomSheet),callback:function ($$v) {_vm.showedBottomSheet=$$v},expression:"showedBottomSheet"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }