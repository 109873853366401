import { Component, Mixins } from "vue-property-decorator";

import FilterTopBarMixin from "../../common/mixins/filterTopBar.mixin";

@Component
export default class MobileFilterTableTopBar extends Mixins(FilterTopBarMixin) {
  private activeFilterFieldIndex = -1;

  private showedFilterFields = false;
  private showedTableHeaderItems = false;
  private showedBottomSheet = false;

  private get activeFilterField() {
    if (
      this.activeFilterFieldIndex === -1 ||
      !Array.isArray(this.filterFields)
    ) {
      return null;
    }

    return this.filterFields[this.activeFilterFieldIndex];
  }

  private get navItems() {
    return [
      {
        icon: "$settings",
        enabled: this.tableHeaderItems.length > 0,
        code: "tableHeaderItems",
        listeners: {
          click: () => {
            this.toggleTableHeaderItems(true);
          },
        },
      },
      {
        icon: "$filter",
        enabled: this.filterFields.length > 0,
        code: "filterFields",
        listeners: {
          click: () => {
            this.toggleFilterFields(true);
          },
        },
      },
      {
        icon: "$reload",
        loading: this.loading,
        enabled: this.showedRefreshButton,
        code: "reload",
        listeners: {
          click: this.onRefresh,
        },
      },
      {
        imgUrl: require("@/assets/img/download-csv.png"),
        enabled: !!this.downloadedDataFunc,
        loading: this.downloadingCSV,
        code: "downloadCSV",
        listeners: {
          click: this.downloadCSV,
        },
      },
      {
        imgUrl: require("@/assets/img/download-excel.png"),
        enabled: !!this.downloadedDataFunc,
        loading: this.downloadingXLSX,
        code: "downloadExcel",
        listeners: {
          click: this.downloadXLSX,
        },
      },
    ].filter(({ enabled }) => enabled !== false);
  }

  private get successButtonOutsideNav() {
    return this.navItems.length > 2;
  }

  private toggleTableHeaderItems(showed = !this.showedTableHeaderItems) {
    this.activeFilterFieldIndex = -1;
    this.showedFilterFields = false;
    this.showedTableHeaderItems = showed;
    this.showedBottomSheet = showed;
  }

  private toggleFilterFields(showed = !this.showedFilterFields) {
    this.activeFilterFieldIndex = -1;
    this.showedTableHeaderItems = false;
    this.showedFilterFields = showed;
    this.showedBottomSheet = showed;
  }

  private setActiveFilterField(index: number) {
    this.activeFilterFieldIndex = index;
  }

  private onBackBottomSheet() {
    this.setActiveFilterField(-1);
  }

  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();
  }
}
